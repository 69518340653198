export default async function downloadApk() {
  try {
      // Make a request to the backend server
      const response = await fetch('https://consultxpert.onrender.com/test/download/app');

      // Check if the request was successful
      if (!response.ok) {
          throw new Error('Failed to download APK');
      }

      // Read the response body as a blob
      const apkBlob = await response.blob();

      // Create a temporary URL for the blob
      const apkUrl = URL.createObjectURL(apkBlob);

      // Create a download link and simulate click to trigger the download
      const link = document.createElement('a');
      link.href = apkUrl;
      link.setAttribute('download', 'suprconsult.apk');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up
      URL.revokeObjectURL(apkUrl);

      console.log('APK downloaded successfully');
  } catch (error) {
      console.error('Error downloading APK:', error);
  }
}

